var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.promoVisible)?_c('div',_vm._b({},'div',_vm.$attrs,false),[(_vm.currentMessage)?_c('div',{staticClass:"vf-promo-bar",class:{ 'vf-promo-bar--redesign-core': _vm.isCoreRedesignEnabled },style:({
      '--vf-promo-bar-color': _vm.currentMessage.titleColor,
      '--vf-promo-bar-background': _vm.backgroundColor,
    }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('div',{staticClass:"vf-promo-bar__main",class:{
        'is-loaded': !_vm.isLoading,
      }},[(_vm.isMultiSlide)?[_c('div',{staticClass:"vf-promo-bar__container"},_vm._l((_vm.currentMessage),function(item,index){return _c('div',{key:("promo-bar-item-" + index),staticClass:"vf-promo-bar__multislide",class:item.titleClassModifier,style:({
              'font-size': _vm.currentMessage.titleFontSize,
              'font-weight': _vm.currentMessage.titleFontWeight,
              'font-family': _vm.currentMessage.titleFontFamily,
            }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(item.id)}},[_c('span',{staticClass:"vf-promo-bar__text",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(item.id, 'teaserTitle')},domProps:{"innerHTML":_vm._s(item.text)}}),_vm._v(" "),_c('ThemeLink',{staticClass:"vf-promo-bar__action",attrs:{"tabindex":"1","data-cm-metadata":_vm.$previewMetaDataLocation(item.id, 'localSettings'),"to":_vm.getPromobarLink(item),"target":_vm.currentMessage.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handlePromoBarAction(item)}}},[_vm._v("\n              "+_vm._s(item.actionText)+"\n            ")]),_vm._v(" "),(index < _vm.currentMessage.length - 1)?_c('div',{staticClass:"vf-promo-bar__separator"},[_vm._v("\n              |\n            ")]):_vm._e()],1)}),0)]:[(_vm.wholeBarClickable)?[_c('ThemeLink',{staticClass:"vf-promo-bar__wide",attrs:{"to":_vm.getPromobarLink(_vm.currentMessage),"target":_vm.currentMessage.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handlePromoBarAction(_vm.currentMessage)}}},[_c('div',{staticClass:"vf-promo-bar__wide-container"},[_c('div',{staticClass:"vf-promo-bar__container",class:_vm.currentMessage.titleClassModifier,attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
                    _vm.$attrs['data-id'],
                    'teaserStyleCustomizations'
                  )},on:{"mouseover":_vm.pauseRotation,"mouseout":_vm.promobarRotation}},[_c('div',{staticClass:"vf-promo-bar__wrapper"},[_c('span',{staticClass:"vf-promo-bar__text",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
                        _vm.currentMessage.id,
                        'teaserTitle'
                      )},domProps:{"innerHTML":_vm._s(_vm.currentMessage.text)}}),_vm._v(" "),_c('span',{staticClass:"vf-promo-bar__action",style:({
                      'font-size': _vm.currentMessage.titleFontSize,
                      'font-weight': _vm.currentMessage.titleFontWeight,
                      'font-family': _vm.currentMessage.titleFontFamily,
                    }),attrs:{"tabindex":"1","data-cm-metadata":_vm.$previewMetaDataLocation(
                        _vm.currentMessage.id,
                        'localSettings'
                      )}},[_vm._v("\n                    "+_vm._s(_vm.currentMessage.actionText)+"\n                  ")])])])])])]:[_c('div',{staticClass:"vf-promo-bar__container",class:_vm.currentMessage.titleClassModifier,attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
                _vm.$attrs['data-id'],
                'teaserStyleCustomizations'
              )},on:{"mouseover":_vm.pauseRotation,"mouseout":_vm.promobarRotation}},[_c('div',{staticClass:"vf-promo-bar__wrapper"},[_c('span',{staticClass:"vf-promo-bar__text",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.currentMessage.id, 'teaserTitle')},domProps:{"innerHTML":_vm._s(_vm.currentMessage.text)}}),_vm._v(" "),_c('ThemeLink',{staticClass:"vf-promo-bar__action",style:({
                  'font-size': _vm.currentMessage.titleFontSize,
                  'font-weight': _vm.currentMessage.titleFontWeight,
                  'font-family': _vm.currentMessage.titleFontFamily,
                }),attrs:{"tabindex":"1","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.currentMessage.id, 'localSettings'),"target":_vm.currentMessage.openInNewTab ? '_blank' : '_self',"to":_vm.getPromobarLink(_vm.currentMessage)},on:{"click":function($event){return _vm.handlePromoBarAction(_vm.currentMessage)}}},[_vm._v("\n                "+_vm._s(_vm.currentMessage.actionText)+"\n              ")])],1)])]]],2),_vm._v(" "),(_vm.showClose)?[_c('div',{staticClass:"vf-promo-bar__close-wrapper"},[_c('UiLink',{staticClass:"vf-promo-bar__close",attrs:{"tabindex":"2","aria-label":_vm.closeLabel},on:{"click":_vm.handleClosePromo}},[_c('VfIcon',{attrs:{"icon":"cross"}})],1)],1)]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('Modal',{staticClass:"vf-promo-bar__modal",attrs:{"visible":_vm.modalVisible},on:{"close":_vm.handleModalClose}},[_c('p',{staticClass:"vf-promo-bar__modal-content",domProps:{"innerHTML":_vm._s(_vm.modalContent)}})])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }