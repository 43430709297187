//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { focusTrap } from '@vf/shared/src/utils/directives';
import { isClient } from '@vf/shared/src/utils/helpers';
import { defineComponent, inject } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

//TODO: remove in GLOBAL15-56318
const isCheckoutRedesignEnabled = () => {
  try {
    return !!useFeatureFlagsStore().isCheckoutRedesignEnabled;
  } catch (e) {
    // working around test suites without pinia (~600 of them)
    return false;
  }
};

export default defineComponent({
  name: 'VfModal',
  directives: { focusTrap },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    /**
     * Heading title of the modal
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Visibility of the modal
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * Cross closing modal button
     */
    cross: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether to show the overlay
     */
    overlay: {
      type: Boolean,
      default: true,
    },
    /**
     * If true clicking outside will not dismiss the modal
     */
    persistent: {
      type: Boolean,
      default: false,
    },
    /**
     * Overlay transition effect
     */
    transitionOverlay: {
      type: String,
      default: '',
    },
    /**
     * Modal transition effect
     */
    transitionModal: {
      type: String,
      default: 'vf-modal-transition',
    },
    /**
     * Custom modal width
     */
    width: {
      type: Object,
      default: () => ({
        medium: '',
        large: '',
      }),
    },
    /**
     * Custom modal height
     */
    height: {
      type: Object,
      default: () => ({
        medium: '',
        large: '',
      }),
    },
    /**
     * Determines if modal is flexible or not (has fixed max width and height)
     */
    flexible: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag if modal is nested in another modal
     */
    isNestedModal: {
      type: Boolean,
      default: false,
    },
    /**
     * Indicator used to detect change of the content in the slot and trigger componentUpdated hook in v-focus-trap directive
     */
    slotChangeIndicator: {
      type: String,
      default: '',
    },
    /**
     * Flag if disableScrollTop should be triggered
     */
    disableScrollTop: {
      type: Boolean,
      default: false,
    },
    additionalData: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Sets the first focus on a focusable element inside a modal
     */
    firstFocus: {
      type: String,
      default: '',
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const { isSimplifiedEnrollmentEnabled } = useFeatureFlagsStore();
    return {
      isCoreRedesignEnabled,
      isSimplifiedEnrollmentEnabled,
    };
  },
  data() {
    return {
      isLockFired: false,
    };
  },
  watch: {
    visible: {
      handler: function (value) {
        if (!isClient || this.disableScrollTop) return;
        if (value) {
          this.$nextTick(() => {
            // Specifically, the target element is the one we would like to allow scroll on (NOT a parent of that element).
            disableBodyScroll(this.$refs.content);
            // Fix body scroll for iOS for 12 version and older
            if (/iP(ad|hone)/.test(navigator.userAgent)) {
              document.body.classList.add('block-scroll');
            }
            this.isLockFired = true;
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          this.clearScrollLocks();
        }
      },
      immediate: isCheckoutRedesignEnabled(),
    },
    width: {
      handler: function (value) {
        if (!isClient) return;
        this.$el.style.setProperty('--modal-width-lg', value.large);
        this.$el.style.setProperty('--modal-width-md', value.medium);
      },
    },
    height: {
      handler: function (value) {
        if (!isClient) return;
        this.$el.style.setProperty('--modal-height-lg', value.large);
        this.$el.style.setProperty('--modal-height-md', value.medium);
        this.$el.style.setProperty('--modal-height-sm', value.small || '');
      },
    },
  },
  beforeDestroy() {
    this.clearScrollLocks();
  },
  methods: {
    close() {
      this.$emit('close', false);
      this.$eventBus.$emit(`modalClose-${this.additionalData.recipe_id}`);
    },
    goBack() {
      this.$emit('goBack');
      this.$eventBus.$emit('modalGoBack');
    },
    checkPersistence() {
      if (!this.persistent) {
        this.close();
      }
    },
    keydownHandler(e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    clearScrollLocks() {
      if (!isClient || !this.isLockFired) return;
      this.$nextTick(() => {
        clearAllBodyScrollLocks();
        this.isLockFired = false;
      });
      // Fix body scroll for iOS for 12 version and older
      if (/iP(ad|hone)/.test(navigator.userAgent)) {
        document.body.classList.remove('block-scroll');
      }
      document.removeEventListener('keydown', this.keydownHandler);
    },
  },
});
