
































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';

export default defineComponent({
  name: 'VfToast',
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    icon: {
      type: String,
    },
    closeIcon: {
      type: String,
    },
    closeIconSize: {
      type: String,
    },
    visibleFor: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    let timeoutId;
    const show = ref(true);
    const close = () => {
      show.value = false;
      emit('close');
    };

    if (props.visibleFor) {
      onMounted(() => {
        timeoutId = setTimeout(close, props.visibleFor);
      });

      onBeforeUnmount(() => {
        clearTimeout(timeoutId);
      });
    }

    return {
      show,
      close,
    };
  },
});
